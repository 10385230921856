

  /* Montserrat */
@font-face {
    font-family: 'Montserrat';
    src: url('../public/fonts/Montserrat-Thin.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Montserrat';
    src: url('../public/fonts/Montserrat-ExtraLight.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Montserrat';
    src: url('../public/fonts/Montserrat-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Montserrat';
    src: url('../public/fonts/Montserrat-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Montserrat';
    src: url('../public/fonts/Montserrat-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Montserrat';
    src: url('../public/fonts/Montserrat-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Montserrat';
    src: url('../public/fonts/Montserrat-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Montserrat';
    src: url('../public/fonts/Montserrat-ExtraBold.ttf') format('truetype');
    font-weight: 800;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Montserrat';
    src: url('../public/fonts/Montserrat-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
  }
  
  /* Source Sans 3 */
  @font-face {
    font-family: 'Source Sans 3';
    src: url('../public/fonts/SourceSans3-ExtraLight.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Source Sans 3';
    src: url('../public/fonts/SourceSans3-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Source Sans 3';
    src: url('../public/fonts/SourceSans3-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Source Sans 3';
    src: url('../public/fonts/SourceSans3-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Source Sans 3';
    src: url('../public/fonts/SourceSans3-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Source Sans 3';
    src: url('../public/fonts/SourceSans3-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Source Sans 3';
    src: url('../public/fonts/SourceSans3-ExtraBold.ttf') format('truetype');
    font-weight: 800;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Source Sans 3';
    src: url('../public/fonts/SourceSans3-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
  }